import "js/state/main" // needs to be there
import "js/core/body"
import "js/core/bootstrap"
import "js/core/capabilities"
import "js/core/components"
import "js/core/cookie"
import "js/core/events"
import "js/core/scroll"
import "js/core/tracking"
import "js/settings"

//import run_iterable from "workerpool/run_iterable"
//async function example() {
//    for await (const r of run_iterable(["python", "eval", ["2+2", "12"]])) {
//        console.log(r)
//    }
//}
//example()


//import singletons from "js/core/singletons"
//import new_file from "js/files/make/new_file"
//import map from "rfuncs/functions/map"
//import values from "rfuncs/functions/values"

//const imposition_url =  "/impaginato/3gAQomZmkoSiZmmtYmlnbGlldHRvLnBkZqJmcqMxLTKicGaiYTGiZnOghKJmabBiaWdsaWV0dG8tdXYucGRmomZyozEtMqJwZqJ2MaJmc6Cib3KhbKJzeqo1LDV4OCw1IGNtonNwoTChY5GIoWygomdyoWKhcgGic2oBom1zpDUgbW2ic2ShMKJwZKQxIG1toWqShKJwbaJmcqJzbagzNXg1MCBjbaJwZqJhMaJtcqCEonBtomZyonNtqDM1eDUwIGNtonBmonYxom1ypDAgbW2ibWuQonBwkKFmom9uonRwo29mZqJicKozeDB4OHg4IG1tomFyo29mZqJmbKhtZWZhZGlub6JzbKCicHOrcGxhaW4tMzV4NTCidHKib26iYW2jYWxs:1sLdNg:l4wbSICyC0JOznnsRUJYgTIpZCJiQICo7GUnACHJ5LQ/json/"
//const filenames = {
//    "biglietto.pdf": "https://workflow-release-data.s3.eu-central-1.amazonaws.com/d/1/YkyCW1p5VwefFchssi3ar.pdf",
//    "biglietto-uv.pdf": "https://workflow-release-data.s3.eu-central-1.amazonaws.com/d/0/GCyv94AMjLHBI8Fjo3U79.pdf",
//}

//const imposition_url =
//    "/impaginato/3gAQomZmkoSiZmmtY29wZXJ0aW5hLnBkZqJmcqExonBmomExomZzoISiZmmraW50ZXJuby5wZGaiZnKkMS0yNKJwZqJhMaJmc6Cib3KhcKJzeqgxNXgxNSBjbaJzcKQzIG1tonF0AaFjkoqhbKlDb3BlcnRpbmGiZ3KhY6FyoTCic2oBom1zwKJzZKYwLDMgbW2icGSkMiBtbaJxdMCibXTAoWqRhaJwbaFmonNtqDM1eDUwIGNtonBqzQEYom1ypDAgbW2icXQBiqFsp0ludGVybm-iZ3KjYjA0oXIGonNqAaJtc8Cic2SmMCwxIG1tonBkpDIgbW2icXTAom10wKFqkYWicG2iZnKic22oMzV4NTAgY22icGrNARiibXKkMCBtbaJxdAGibWuQoWaib26idHCib26iYnCkMCBtbaJhcqNvZmaicHO4bWV0YWwtc3RhcGxlLWNvdmVyLTM1eDUwomZspmNsaWR1aaJzbKCidHKib26iYW2jb2Zm:1tOXpC:yh2JmZgy6d1vvJQi2iIe7u9edASJw-EPrZ6vN5D1omQ/json/"
//const filenames = {
//    "interno.pdf":
//        "https://workflow-release-data.s3.eu-central-1.amazonaws.com/d/7/c2SypIolitVTYm7DfwpQp.pdf",
//    "copertina.pdf":
//        "https://workflow-release-data.s3.eu-central-1.amazonaws.com/d/4/l9T5v5G14TN4o91bFfWL1.pdf"
//}

//singletons.pdfmodal({
//    filelist: values(map((url, name) => new_file({ url, name }), filenames)),
//    imposition_url,
//    hide_sidebar: true,
//    initial_view: "plot_3d"
//})

//const imposition_url =  "/impaginato/book-35x50/json/?b64=eyJmZiI6W3siZmkiOiJib29rLnBkZiIsImZyIjoiMS0xMCIsInBmIjoiYTEiLCJmcyI6IiJ9XSwib3IiOiJwIiwic3oiOiIyMXgyOSw3IGNtIiwic3AiOiI1IG1tIiwicXQiOjEsImMiOlt7ImwiOiJDb3BlcnRpbmEiLCJnciI6ImYxIiwiciI6MSwic2oiOjEsIm1zIjoiNSBtbSIsInNkIjoiMjk5IHVtIiwicGQiOiI1IG1tIiwicXQiOm51bGwsIm10IjpudWxsLCJqIjpbeyJwbSI6ImZyIiwic20iOm51bGwsInBqIjoiYTEiLCJtciI6IjAgbW0iLCJxdCI6bnVsbH1dfSx7ImwiOiJJbnRlcm5vIiwiZ3IiOiJiIiwiciI6MTAsInNqIjoxLCJtcyI6IjUgbW0iLCJzZCI6IjEwMCB1bSIsInBkIjoiNSBtbSIsInF0IjpudWxsLCJtdCI6bnVsbCwiaiI6W3sicG0iOiJmciIsInNtIjpudWxsLCJwaiI6ImExIiwibXIiOiIwIG1tIiwicXQiOm51bGx9XX1dLCJtayI6W10sImYiOiJvbiIsInRwIjoib24iLCJicCI6IjN4MHg4eDggbW0iLCJhciI6Im9mZiIsInBzIjoiZmlsZWNoZWNrIiwiZmwiOiJyaWxlZ2F0dXJhLXB1bnRvLW1ldGFsbGljbyIsInNsIjoiIiwidHIiOiJvbiIsImFtIjoib2ZmIn0&b64=eyJ0ciI6Im9uIiwiYW0iOiJvZmYifQ"
//const filenames = {
//    "book.pdf":
//        "/impaginato/book-35x50/pdf/?b64=eyJmZiI6W3siZmkiOiJib29rLnBkZiIsImZyIjoiMS0xMCIsInBmIjoiYTEiLCJmcyI6IiJ9XSwib3IiOiJwIiwic3oiOiIyMXgyOSw3IGNtIiwic3AiOiI1IG1tIiwicXQiOjEsImMiOlt7ImwiOiJDb3BlcnRpbmEiLCJnciI6ImYxIiwiciI6MSwic2oiOjEsIm1zIjoiNSBtbSIsInNkIjoiMjk5IHVtIiwicGQiOiI1IG1tIiwicXQiOm51bGwsIm10IjpudWxsLCJqIjpbeyJwbSI6ImZyIiwic20iOm51bGwsInBqIjoiYTEiLCJtciI6IjAgbW0iLCJxdCI6bnVsbH1dfSx7ImwiOiJJbnRlcm5vIiwiZ3IiOiJiIiwiciI6MTAsInNqIjoxLCJtcyI6IjUgbW0iLCJzZCI6IjEwMCB1bSIsInBkIjoiNSBtbSIsInF0IjpudWxsLCJtdCI6bnVsbCwiaiI6W3sicG0iOiJmciIsInNtIjpudWxsLCJwaiI6ImExIiwibXIiOiIwIG1tIiwicXQiOm51bGx9XX1dLCJtayI6W10sImYiOiJvbiIsInRwIjoib24iLCJicCI6IjN4MHg4eDggbW0iLCJhciI6Im9mZiIsInBzIjoiZmlsZWNoZWNrIiwiZmwiOiJyaWxlZ2F0dXJhLXB1bnRvLW1ldGFsbGljbyIsInNsIjoiIiwidHIiOiJvbiIsImFtIjoib2ZmIn0&b64=eyJ0ciI6Im9uIiwiYW0iOiJvZmYifQ",
//}
//singletons.pdfmodal({
//    filelist: values(map((url, name) => new_file({ url, name }), filenames)),
//    imposition_url,
//    hide_sidebar: true,
//    initial_view: "plot_3d"
//})

//import append from "js/dom/append"
//import clear from "js/dom/clear"
//import el from "js/dom/element"
//import storage from "js/state/storage"
//import imposition_plot from "workflow/actions/imposition_plot"
//import imposition_plot_target from "workflow/actions/imposition_plot_target"

//imposition_plot_target({imposition_url, filenames, target: 'emisinumi-03-interno~a1.pdf'}).then(r => {
//    console.log('plot done 🎊' , r)
//    if (r.url) {
//        clear('body')
//        append('body', el('iframe', {'src': r.url, 'class': 'w-100 d-flex', 'style': 'height: 100vh'}))
//    }
//})

//imposition_plot({imposition_url, filenames, prefix: `${new Date().toISOString()}`}).then(files => map(new_file, files)).then(storage.add_files)
